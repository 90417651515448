import { readableFontColor, getReadability } from '../../../../utils/colors';

export default theme => ({
  heading: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  headingWrapper: {
    borderBottom: '1px solid #E7E7E7',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  highlight: {
    backgroundColor: theme.palette.red.main,
    borderRadius: theme.spacing(0.5),
    color: `${theme.palette.white.main} !important`,
    marginRight: -theme.spacing(0.5),
    paddingLeft: `${theme.spacing(0.5)}px !important`,
    paddingRight: `${theme.spacing(0.5)}px !important`,
  },
  modifierContainer: {
    '&:last-child': {
      borderBottom: 0,
      marginBottom: 0,
    },
    alignItems: 'center',
    borderBottom: props => (props.nestingLevel > 0 ? 'none' : '1px solid #E7E7E7'),
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  modifierCount: {
    alignItems: 'flex-end',
    color: 'rgb(117, 117, 117)',
    display: 'flex',
    flexDirection: 'column',
    float: 'right',
    marginLeft: theme.spacing(0.5),
    padding: theme.spacing(0.5),
  },
  modifierCountError: {
    color: '#FF0000',
  },
  modifierError: {
    color: '#FF0000',
    fontWeight: 'normal',
    textTransform: 'none',
  },
  modifierGroupContainer: {
    backgroundColor: props => (props.nestingLevel % 2 === 0 ? theme.palette.white.main : theme.palette.gray.light),
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(2),
    padding: props => (props.nestingLevel > 0 ? theme.spacing(2) : 0),
  },
  quantityInput: {
    maxWidth: '200px',
  },
  requiredItemPass: {
    backgroundColor: theme.palette.text.disabled,
    borderRadius: theme.spacing(0.5),
    color: `${theme.palette.white.main} !important`,
    marginRight: -theme.spacing(0.5),
    paddingLeft: `${theme.spacing(0.5)}px !important`,
    paddingRight: `${theme.spacing(0.5)}px !important`,
  },
  selectionText: {
    fontSize: '0.8rem',
  },
  textSecondary: {
    color: getReadability('#FFF', theme.palette.secondary.main) < 1.25 ? `${readableFontColor(theme.palette.secondary.main)} !important` : theme.palette.secondary.main,
  },
});
