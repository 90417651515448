import React, { useState } from 'react';
import { useQuery } from '~/lazy_apollo/client';

import { toInt } from '../../../../utils/utils';
import menuItemCartSelectedModifierQuery from '../../../../libs/gql/queries/menu_item_carts/menuItemCartSelectedModifierQuery.gql';

import { useLoadedModifierGroups } from '../LoadedModifierGroupsProvider';
import SelectedNestedModifiers from '../SelectedNestedModifiers';

const getSelectedModifiers = (modifierGroup, selectedModifierGroups) => {
  if (!modifierGroup || !selectedModifierGroups) {
    return [];
  }
  const selectedModifierGroup = selectedModifierGroups.find(smg => smg && smg.menuItemModifierGroupId === modifierGroup.id);
  return modifierGroup.modifiers.map((modifier) => {
    const selectedModifier = selectedModifierGroup && selectedModifierGroup.selectedModifiers.find(sm => sm && sm.menuItemModifierId === modifier.id);
    return ({
      id: selectedModifier?.id,
      menuItemModifierId: modifier.id,
      quantity: (selectedModifier ? toInt(selectedModifier.quantity) : (modifier.isDefault ? 1 : 0)) || 0,
    });
  });
};

const SelectedModifierQuery = ({ calculateOutOfStock, getExtraTitle, nestingLevel, selectedModifierId }) => {
  const [loadedModifierGroups, setLoadedModifierGroups] = useState();
  const { updateLoadedModifierGroups } = useLoadedModifierGroups();
  const { data, loading } = useQuery(
    menuItemCartSelectedModifierQuery,
    {
      variables: {
        id: selectedModifierId,
      },
    },
  );

  if (loading || !data || !data.menuItemCartSelectedModifier) {
    return null;
  }
  const { menuItemCartSelectedModifier: selectedModifier } = data;
  const { modifierGroups } = selectedModifier.menuItemModifier;

  // Update state so infinite loop does not occur and write modifier groups to context
  if (!loadedModifierGroups) {
    setLoadedModifierGroups(modifierGroups);
    updateLoadedModifierGroups(modifierGroups);
  }

  const selectedModifierGroups = modifierGroups.map(modifierGroup => ({
    menuItemModifierGroupId: modifierGroup.id,
    selectedModifiers: getSelectedModifiers(modifierGroup, selectedModifier.selectedModifierGroups),
  }));

  return (
    <SelectedNestedModifiers
      calculateOutOfStock={calculateOutOfStock}
      defaultSelectedModifierGroups={selectedModifierGroups}
      getExtraTitle={getExtraTitle}
      modifierGroups={selectedModifier.menuItemModifier.modifierGroups}
      nestingLevel={nestingLevel}
    />
  );
};

export default SelectedModifierQuery;
