export default theme => ({
  addToCartButtonWrapper: {
    padding: `${theme.spacing(2)}px 0 !important`,
  },
  addToCartModalBtn: {
    alignContent: 'center',
    height: theme.spacing(6.9),
  },
  addToCartModalDescription: {
    marginTop: theme.spacing(2),
  },
  addToCartModalFooter: {
    [theme.breakpoints.down('sm')]: {
      margin: `${theme.spacing(1)}px ${theme.spacing(-1)}px 0 ${theme.spacing(-1)}px`,
    },
    backgroundColor: 'white',
    bottom: theme.spacing(-1.2),
    boxShadow: '0px -10px 15px rgb(242, 242, 242)',
    margin: `${theme.spacing(1)}px ${theme.spacing(-3)}px 0 ${theme.spacing(-3)}px`,
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px 0 ${theme.spacing(2)}px`,
    position: 'sticky',
    zIndex: 3,
  },
  addToCartModalFooterContentWrapper: {
    padding: `0 ${theme.spacing(1)}px`,
  },
  addToCartModalTitle: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(4),
      top: 0,
    },
    backgroundColor: 'white',
    margin: '0px -24px',
    padding: `${theme.spacing(4)}px ${theme.spacing(2)}px ${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
    position: 'sticky',
    top: theme.spacing(-2.5),
    zIndex: 2,
  },
  addToCartModalTitleShadow: {
    boxShadow: '0px 10px 15px rgb(242, 242, 242)',
  },
  contentWrapper: {
    [theme.breakpoints.down('md')]: {
      minHeight: `calc(100vh - 130px - ${theme.spacing(3)}px)`,
    },
    marginBottom: theme.spacing(2),
  },
  menuItemExtra: {
    display: 'block',
    marginLeft: theme.spacing(4),
  },
  menuItemPrice: {
    float: 'right',
    marginLeft: theme.spacing(1),
  },
  menuItemTotal: {
    marginTop: theme.spacing(1),
    textAlign: 'right',
  },
  menuItemTotalDisclaimer: {
    opacity: 0.4,
    textAlign: 'right',
  },
  modernContentWrapper: {
    minHeight: '415px',
  },
  modifierModernOption: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  modifierModernSubheading: {
    '& > span': {
      '&.modifier-optional': {
        backgroundColor: '#FFF !important',
      },
      '&:first-child': {
        fontSize: '18px',
      },
      '&:last-child': {
        alignItems: 'center',
        backgroundColor: '#eec473',
        borderRadius: '50px',
        display: 'flex',
        fontSize: '12px',
        height: '28px',
        padding: '6px 10px',
      },
      color: '#473F2F',
    },
    display: 'flex',
    justifyContent: 'space-between',
  },
  nextButton: {},
  outOfStockLabel: {
    backgroundColor: theme.palette.gray.main,
    border: `1px solid ${theme.palette.gray.main}`,
    borderRadius: '4px',
    fontSize: '0.8em',
    marginLeft: theme.spacing(1),
    padding: theme.spacing(0.5, 1),
  },
  outOfStockOpacity: {
    opacity: 0.38,
  },
  sectionContainer: {
    marginBottom: theme.spacing(4),
  },
  sectionHeading: {
    borderBottom: '1px solid #E7E7E7',
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(0.5),
  },
  selectedDishDiscrepancyWording: {
    color: theme.palette.red.light,
  },
});
