import gql from 'graphql-tag';
import { toInt } from '../../../utils/numbers';

const menuItemCartModifierGroupFragment = gql`
  fragment selectedMenuItemModifierGroup on MenuItemModifierGroup {
    __typename
    id
    freeSelectionsCount
    modifiers {
      __typename
      id
      price
    }
}`;

const sumSelectedModifiers = (selectedModifierGroups, modifierGroupsData, client) => {
  let total = 0;
  selectedModifierGroups.forEach(({ menuItemModifierGroupId, selectedModifiers }) => {
    if (!menuItemModifierGroupId) { return; }

    const modifierGroup = (modifierGroupsData.find(mg => mg.id === menuItemModifierGroupId) ||
      client.readFragment({
        fragment: menuItemCartModifierGroupFragment,
        id: `MenuItemModifierGroup:${menuItemModifierGroupId}`,
      }));
    if (!modifierGroup) { return; }

    const sortModifiersByPrice = (modifiers, modifiersData) => {
      const sortedModifiers = [...modifiers];

      sortedModifiers.sort((m1, m2) => {
        const m1Price = modifiersData.find(m => m.id === m1.menuItemModifierId).price;
        const m2Price = modifiersData.find(m => m.id === m2.menuItemModifierId).price;
        return (m1Price - m2Price);
      });

      return sortedModifiers;
    };

    // if there are free modifiers included we auto-set the least expensive as free (to match Aloha logic)
    const sortedModifiers = sortModifiersByPrice(selectedModifiers, modifierGroup.modifiers);

    let overallSelectionsCount = 0; // for a given group
    sortedModifiers.forEach((selectedModifier) => {
      if (!selectedModifier.quantity || selectedModifier.quantity === 0) { return; }
      let modifierFreeSelectionsCount = 0;

      const modifier =
        selectedModifier.menuItemModifierId &&
        modifierGroup.modifiers.find(m => m.id === selectedModifier.menuItemModifierId);
      if (!modifier) { return; }

      const isModifierCoveredByFreeSelections =
        (overallSelectionsCount + selectedModifier.quantity) <= modifierGroup.freeSelectionsCount;
      const isAnyFreeSelectionsLeft = overallSelectionsCount < modifierGroup.freeSelectionsCount;

      if (isModifierCoveredByFreeSelections) {
        overallSelectionsCount += selectedModifier.quantity;
        return;
      } else if (isAnyFreeSelectionsLeft) {
        modifierFreeSelectionsCount = modifierGroup.freeSelectionsCount - overallSelectionsCount;
        overallSelectionsCount += selectedModifier.quantity;
      }

      if (modifier.price) {
        total += (modifier.price * selectedModifier.quantity);
        total -= (modifier.price * modifierFreeSelectionsCount);
      }

      if (selectedModifier.selectedModifierGroups?.length > 0) {
        // recursive call handles nested modifiers
        total += sumSelectedModifiers(selectedModifier.selectedModifierGroups, modifierGroupsData, client);
      }
    });
  });
  return total;
};

const calculateMenuItemSubtotal = (menuItem, values, isNestedModifiersEnabled, modifierGroupsData, client, modernLayout = false) => {
  if (values.posMenuItemPriceOverride || values.posMenuItemPriceOverride === 0) {
    if (modernLayout) {
      return values.posMenuItemPriceOverride.toFixed(2);
    } else {
      return values.posMenuItemPriceOverride;
    }
  }

  let subtotal = values.adjustedMenuItemPrice || 0;

  // Take selected size price or base price
  if (menuItem.sizes.length > 0) {
    if (values.selectedMenuItemSizeId) {
      const size = menuItem.sizes.find(s => s.id === values.selectedMenuItemSizeId);
      if (size && size.price) {
        subtotal += size.price;
      }
    }
  } else if (menuItem.price) {
    subtotal += menuItem.price;
  }

  if (isNestedModifiersEnabled) {
    // Add selected modifier group modifiers
    subtotal += sumSelectedModifiers(values.selectedModifierGroups, modifierGroupsData, client);
  } else {
    // Add selected extras
    values.selectedExtras.forEach((selectedExtra) => {
      const extra = selectedExtra && selectedExtra.menuItemExtraId && menuItem.extras.find(e => e.id === selectedExtra.menuItemExtraId);
      if (extra && extra.price) {
        subtotal += extra.price;
      }
    });

    // Add selected extra group extras
    values.selectedExtraGroups.forEach((selectedExtraGroup) => {
      const extraGroup = selectedExtraGroup.menuItemExtraGroupId && menuItem.extraGroups.find(eg => eg.id === selectedExtraGroup.menuItemExtraGroupId);
      if (extraGroup) {
        selectedExtraGroup.selectedExtras.forEach(({ extraId, quantity }) => {
          const extra = extraId && extraGroup.extras.find(e => e.id === extraId);
          if (extra && extra.price) {
            subtotal += (extra.price * toInt(quantity));
          }
        });
      }
    });

    // Add selected shared extra group extras
    values.selectedSharedExtraGroups.forEach((selectedSharedExtraGroup) => {
      const sharedExtraGroup = selectedSharedExtraGroup.menuItemSharedExtraGroupId && menuItem.sharedExtraGroups.find(eg => eg.id === selectedSharedExtraGroup.menuItemSharedExtraGroupId);
      if (sharedExtraGroup) {
        selectedSharedExtraGroup.selectedExtras.forEach(({ extraId, quantity }) => {
          const extra = extraId && sharedExtraGroup.sharedExtraGroup.extras.find(e => e.id === extraId);
          if (extra && extra.price) {
            subtotal += (extra.price * toInt(quantity));
          }
        });
      }
    });
  }

  if (modernLayout) {
    const suggestedMenuItemsTotal = values.suggestedMenuItems
      .filter(item => !!item?.suggestedMenuItem)
      .reduce((acc, { suggestedMenuItem }) => {
        const price = suggestedMenuItem.price || 0;
        return acc + price;
      }, 0);

    return ((subtotal * (values.quantity || 0)) + suggestedMenuItemsTotal).toFixed(2);
  } else {
    return subtotal * (values.quantity || 0);
  }
};

export default calculateMenuItemSubtotal;
