import React from 'react';

import SelectedModifierQuery from '../SelectedModifierQuery';
import MenuItemModifierQuery from '../MenuItemModifierQuery';

const SelectedModifierQuerySelector = ({ calculateOutOfStock, getExtraTitle, menuItemModifierId, nestingLevel, selectedModifierId }) => (
  <React.Fragment>
    {selectedModifierId ? (
      <SelectedModifierQuery
        calculateOutOfStock={calculateOutOfStock}
        getExtraTitle={getExtraTitle}
        nestingLevel={nestingLevel}
        selectedModifierId={selectedModifierId}
      />
    ) : (
      <MenuItemModifierQuery
        calculateOutOfStock={calculateOutOfStock}
        getExtraTitle={getExtraTitle}
        menuItemModifierId={menuItemModifierId}
        nestingLevel={nestingLevel}
      />
    )}
  </React.Fragment>
);

export default SelectedModifierQuerySelector;
