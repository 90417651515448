import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Typography } from '@popmenu/common-ui';

import { FormattedMessage } from 'react-intl';
import useVariable from '../../../../utils/useVariable';
import { preventNegative } from '../../../../utils/events';
import { toInt } from '../../../../utils/numbers';
import { classNames, makeStyles } from '../../../../utils/withStyles';
import styles from './styles';

import CheckBox from '../../../../admin/shared/forms/CheckBox';
import Divider from '../../../../admin/shared/forms/Divider';
import Field from '../../../../admin/shared/forms/Field';
import NumberInputField from '../../../../admin/shared/forms/NumberInputField';
import NestedCollection from '../../../../admin/shared/forms/NestedCollection';
import SelectedModifierQuerySelector from '../SelectedModifierQuerySelector';
import TextField from '../../../../admin/shared/forms/TextField';

const useStyles = makeStyles(styles);

const ModifierGroupOptions = (props) => {
  const { calculateOutOfStock, getExtraTitle, modifierGroup, nestingLevel } = props;
  const classes = useStyles(props);
  const [showQuantityInputIds] = useVariable([]);
  const { freeSelectionsCount, maxSelectionsCount, minSelectionsCount, multiSameSelection } = modifierGroup;

  const selectionText = () => {
    if (minSelectionsCount > 0) {
      // required options
      if (maxSelectionsCount < 1) {
        // no max
        return `Select at least ${minSelectionsCount}`;
      } else if (maxSelectionsCount === minSelectionsCount) {
        // specific amount
        return `Select ${maxSelectionsCount}`;
      } else {
        // up to a certain amount
        return `Select between ${minSelectionsCount} and ${maxSelectionsCount}`;
      }
    } else if (minSelectionsCount < 1 && maxSelectionsCount > 0) {
      return `Select up to ${maxSelectionsCount}`;
    }
    return null;
  };

  const freeSelectionsText = () => {
    if (freeSelectionsCount > 0 && freeSelectionsCount < maxSelectionsCount) {
      const selectionWord = freeSelectionsCount > 1 ? 'selections' : 'selection';
      return `Includes ${freeSelectionsCount} free ${selectionWord}`;
    }
    return null;
  };

  const showRadio = minSelectionsCount === 1 && maxSelectionsCount === 1;
  const MultiSameSelectionComponent = props.modernLayout ? NumberInputField : TextField;

  return (
    <NestedCollection field="selectedModifiers">
      {({ setFieldValue, values: selectedModifiers }) => {
        if (!selectedModifiers) return null;
        const selectedCount = selectedModifiers.reduce((sum, selectedModifier) => sum + toInt(selectedModifier && selectedModifier.quantity) || 0, 0);
        const isRequired = minSelectionsCount > 0;
        const hasMissingRequiredItems = isRequired && (selectedCount > maxSelectionsCount || selectedCount < minSelectionsCount);
        return (
          <Box>
            <Box className={classes.modifierGroupContainer}>
              <Box className={classes.headingWrapper}>
                <Typography className={classes.heading}>
                  {modifierGroup.name}
                </Typography>
                <Box className={classNames(classes.modifierCount)}>
                  <Typography className={classNames(isRequired ? (hasMissingRequiredItems ? classes.highlight : classes.requiredItemPass) : null)}>
                    {isRequired ? 'Required' : 'Optional'}
                  </Typography>
                  <Typography className={classNames(classes.selectionText, hasMissingRequiredItems ? classes.modifierError : null)}>
                    {selectionText()}
                  </Typography>
                  <Typography className={classNames(classes.selectionText, hasMissingRequiredItems ? classes.modifierError : null)}>
                    {freeSelectionsText()}
                  </Typography>
                </Box>
              </Box>
              {selectedModifiers.map((selectedModifier, index) => {
                const modifier = modifierGroup.modifiers.find(e => e.id === selectedModifier.menuItemModifierId);
                const outOfStock = calculateOutOfStock(modifier);
                if (!modifier) {
                  return null;
                }
                const isAllSelectionsFree = maxSelectionsCount === freeSelectionsCount;
                const remainingFreeSelectionsCount = freeSelectionsCount - selectedCount;
                const hideModifiersPrices = isAllSelectionsFree || remainingFreeSelectionsCount > 0;

                return (
                  <React.Fragment>
                    <Field key={index} field={index}>
                      <Box className={classes.modifierContainer}>
                        <Typography>
                          <label htmlFor={`modifiers ${modifier.name}`} style={{ all: 'unset' }}>
                            {getExtraTitle(modifier, outOfStock, true, hideModifiersPrices)}
                          </label>
                        </Typography>
                        <Box>
                          <Field field="quantity">
                            {({ setValue: setQuantity, value }) => {
                              const quantity = toInt(value) || 0;
                              const isMultiMaxSelections = maxSelectionsCount >= 0;
                              const isValidMaximum = maxSelectionsCount >= 0;
                              const isLimitedMaxSelections = maxSelectionsCount > 0;
                              const maxOptionsSelected = !quantity && isLimitedMaxSelections && isValidMaximum && selectedCount >= maxSelectionsCount && maxSelectionsCount !== 1;
                              if (multiSameSelection && isValidMaximum && isMultiMaxSelections) {
                                if (quantity === 0 && showQuantityInputIds.indexOf(modifier.id) === -1) {
                                  return (
                                    <Button
                                      className={classes.textSecondary}
                                      disabled={outOfStock}
                                      onClick={() => {
                                        if (selectedCount < maxSelectionsCount || maxSelectionsCount === null) {
                                          setQuantity(1);
                                          // Always show quantity input once selected to prevent confusion when focusing input
                                          showQuantityInputIds.push(modifier.id);
                                        }
                                      }}
                                      variant="text"
                                    >
                                      <FormattedMessage
                                        id="custom_pages.add"
                                        defaultMessage="Add"
                                      />
                                    </Button>
                                  );
                                }
                                return (
                                  <MultiSameSelectionComponent
                                    preventNegativeValues
                                    className={classes.quantityInput}
                                    field="quantity"
                                    InputProps={{
                                      inputProps: {
                                        'aria-label': `Edit ${modifier.name} quantity`,
                                      },
                                    }}
                                    onChange={(e) => {
                                      setQuantity(e.target.value);
                                    }}
                                    onIncrementDown={() => {
                                      if (quantity && quantity > 0) {
                                        setQuantity(quantity - 1);
                                      }
                                      if (quantity === 1) {
                                        const modifierIndex = showQuantityInputIds.indexOf(modifier.id);
                                        showQuantityInputIds.splice(modifierIndex, 1);
                                      }
                                    }}
                                    onIncrementUp={() => {
                                      if (selectedCount < maxSelectionsCount) {
                                        setQuantity((quantity || 0) + 1);
                                      }
                                    }}
                                    onKeyPress={preventNegative}
                                    showSteppers
                                    type="number"
                                    value={value}
                                  />
                                );
                              }
                              return (
                                <CheckBox
                                  checked={quantity >= 1}
                                  data-cy="modifier_checkbox"
                                  disabled={maxOptionsSelected || outOfStock}
                                  field="quantity"
                                  id={`modifiers ${modifier.name}`}
                                  onChange={() => {
                                    if (showRadio) {
                                      // Clear out other selected modifiers when exactly 1 option must be selected
                                      selectedModifiers.forEach((selectedModifierValue, i) => {
                                        if (selectedModifierValue.quantity >= 1 && selectedModifierValue.menuItemModifierId !== modifier.id) {
                                          setFieldValue([i], { ...selectedModifierValue, quantity: 0 });
                                        } else if (selectedModifierValue.quantity === 0 && selectedModifierValue.menuItemModifierId === modifier.id) {
                                          setFieldValue([i], { ...selectedModifierValue, quantity: 1 });
                                        }
                                      });
                                    } else if (maxSelectionsCount === 1) {
                                      // Clear out other selected modifiers and/or existing selection when up to 1 option can be selected
                                      selectedModifiers.forEach((selectedModifierValue, i) => {
                                        if (selectedModifierValue.quantity >= 1) {
                                          setFieldValue([i], { ...selectedModifierValue, quantity: 0 });
                                        } else if (selectedModifierValue.quantity === 0 && selectedModifierValue.menuItemModifierId === modifier.id) {
                                          setFieldValue([i], { ...selectedModifierValue, quantity: 1 });
                                        }
                                      });
                                    } else {
                                      setFieldValue([index], { ...selectedModifier, quantity: quantity >= 1 ? 0 : 1 });
                                    }
                                  }}
                                  radio={showRadio}
                                  rootClassName={classes.textSecondary}
                                  value="1"
                                />
                              );
                            }}
                          </Field>
                        </Box>
                      </Box>
                      {selectedModifier.quantity >= 1 && (
                        <SelectedModifierQuerySelector
                          calculateOutOfStock={calculateOutOfStock}
                          getExtraTitle={getExtraTitle}
                          menuItemModifierId={modifier.id}
                          nestingLevel={nestingLevel + 1}
                          selectedModifierId={selectedModifier.id}
                        />
                      )}
                    </Field>
                  </React.Fragment>
                );
              })}
            </Box>
            <Divider />
          </Box>
        );
      }}
    </NestedCollection>
  );
};

ModifierGroupOptions.defaultProps = {
  nestingLevel: 0,
};

ModifierGroupOptions.propTypes = {
  calculateOutOfStock: PropTypes.func.isRequired,
  getExtraTitle: PropTypes.func.isRequired,
  modifierGroup: PropTypes.shape({
    modifiers: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      price: PropTypes.number,
    })),
    name: PropTypes.string,
  }).isRequired,
  nestingLevel: PropTypes.number,
};

export default ModifierGroupOptions;
