import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'react-form';

// For compatibility with legacy react-form <Field /> component in class components that cannot use hooks
const Field = ({ field, children, validate }) => {
  const { FieldScope, setValue, value, meta: { error }, debounce } = useField(String(field), { validate });
  if (typeof children === 'function') {
    return children({
      debounce,
      error,
      setValue,
      value,
    });
  }
  return (
    <FieldScope>
      {children}
    </FieldScope>
  );
};

Field.defaultProps = {
  validate: null,
};

Field.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
  ]).isRequired,
  field: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  validate: PropTypes.func,
};

export default Field;
