import React from 'react';
import { Box, Typography } from '@popmenu/common-ui';

import { CheckBoxGroup, NestedField } from '../../../../admin/shared/forms/BasicForm';
import ExtraGroupOptions from '../ExtraGroupOptions';

const SelectedExtrasContainer = ({ calculateOutOfStock, getExtraTitle, menuItem, modernLayout }) => (
  <React.Fragment>
    {menuItem.extras.length > 0 && (
      <NestedField field="selectedExtras">
        {menuItem.extras.map((extra, i) => (
          <NestedField className={'modern-menu-item-extra-option'} key={extra.id} field={i}>
            <CheckBoxGroup
              disabled={calculateOutOfStock(extra)}
              field="menuItemExtraId"
              id={`extras ${extra.name}`}
              title={(
                <Typography>
                  <label htmlFor={`extras ${extra.name}`} style={{ all: 'unset' }}>
                    {getExtraTitle(extra, calculateOutOfStock(extra))}
                  </label>
                </Typography>
              )}
              value={extra.id}
            />
          </NestedField>
        ))}
      </NestedField>
    )}
    {menuItem.extraGroups.length > 0 && (
      <NestedField field="selectedExtraGroups">
        {menuItem.extraGroups.map((extraGroup, i) => (
          <NestedField key={extraGroup.id} field={i}>
            <ExtraGroupOptions
              calculateOutOfStock={calculateOutOfStock}
              modernLayout={modernLayout}
              extraGroup={extraGroup}
              minSelectionsCount={extraGroup.minSelectionsCount}
              maxSelectionsCount={extraGroup.maxSelectionsCount}
              getExtraTitle={getExtraTitle}
            />
          </NestedField>
        ))}
      </NestedField>
    )}
    {menuItem.sharedExtraGroups.length > 0 && (
      <Box>
        <NestedField field="selectedSharedExtraGroups">
          {menuItem.sharedExtraGroups
            .filter(sharedExtraGroup => sharedExtraGroup.isEnabled)
            .map((sharedExtraGroup, i) => (
              <NestedField key={sharedExtraGroup.id} field={i}>
                <ExtraGroupOptions
                  calculateOutOfStock={calculateOutOfStock}
                  modernLayout={modernLayout}
                  extraGroup={sharedExtraGroup.sharedExtraGroup}
                  minSelectionsCount={sharedExtraGroup.minSelectionsCount}
                  maxSelectionsCount={sharedExtraGroup.maxSelectionsCount}
                  getExtraTitle={getExtraTitle}
                />
              </NestedField>
            ))}
        </NestedField>
      </Box>
    )}
  </React.Fragment>
);

export default SelectedExtrasContainer;
