// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import type { ApolloClient, ApolloLink, RequestHandler } from '@apollo/client/core';

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useApolloClient as useApolloClientLib } from '@apollo/client';

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
export * from '@apollo/client';

// It doesn't really return `null` value yet. But it will return it later after lazy loading Apollo Client
export const useApolloClient = useApolloClientLib as unknown as (override?: ApolloClient<object>) => (ApolloClient<object> | null);

export type ApolloMiddleware = RequestHandler | ApolloLink
