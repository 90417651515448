import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@popmenu/common-ui';

import { NestedCollection, NestedField } from '../../../../admin/shared/forms/BasicForm';
import ModifierGroupOptions from '../ModifierGroupOptions';

const SelectedNestedModifiers = ({ calculateOutOfStock, defaultSelectedModifierGroups, getExtraTitle, modifierGroups, nestingLevel }) => (
  <React.Fragment>
    {modifierGroups.length > 0 && (
      <Box>
        <NestedCollection field="selectedModifierGroups">
          {({ setValue, values: selectedModifierGroups }) => {
            if (!selectedModifierGroups) {
              setValue(defaultSelectedModifierGroups);
            }
            return (
              <React.Fragment>
                {modifierGroups.map((modifierGroup, i) => (
                  <NestedField key={modifierGroup.id} field={i}>
                    <ModifierGroupOptions
                      calculateOutOfStock={calculateOutOfStock}
                      getExtraTitle={getExtraTitle}
                      modifierGroup={modifierGroup}
                      nestingLevel={nestingLevel}
                    />
                  </NestedField>
                ))}
              </React.Fragment>
            );
          }}
        </NestedCollection>
      </Box>
    )}
  </React.Fragment>
);

SelectedNestedModifiers.propTypes = {
  calculateOutOfStock: PropTypes.func.isRequired,
  getExtraTitle: PropTypes.func.isRequired,
  menuItem: PropTypes.shape({
    modifierGroups: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      isEnabled: PropTypes.bool,
      maxSelectionsCount: PropTypes.number,
      minSelectionsCount: PropTypes.number,
      modifiers: PropTypes.shape({
        id: PropTypes.number,
        isEnabled: PropTypes.bool,
        outOfStock: PropTypes.bool,
        outOfStockUntil: PropTypes.string,
        price: PropTypes.number,
        priceCustomText: PropTypes.string,
        priceType: PropTypes.string,
      }),
      multiSameSelection: PropTypes.bool,
    })),
  }).isRequired,
};

export default SelectedNestedModifiers;
