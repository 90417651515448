import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'react-form';

import { NumberField } from '@popmenu/common-ui';

import FormGroup from './FormGroup';
import { toFloat } from '../../../utils/numbers';

const NumberInputField = (props) => {
  const { characterLimit, disabled, field, incrementor = 1, inline, maxValue, maxValueNotice, onTextChange, FormGroupProps, preventNegativeValues, validate, size, ...inputProps } = props;
  const { value, setValue, meta: { error } } = useField(field, { validate });

  const incrementorDecimalPointsCount = (incrementor.toString().split('.')[1] || '').length;
  const mathRoundMultiplier = incrementorDecimalPointsCount > 0 ? incrementorDecimalPointsCount * 10 : 1;

  const onChange = useCallback((e) => {
    const val = e.target.value;
    if (preventNegativeValues && (val < 0 || Number.isNaN(val))) return;

    setValue(val);
    if (onTextChange) onTextChange(val);
  }, [onTextChange, preventNegativeValues, setValue]);
  const onIncrementDown = useCallback(() => { setValue(Math.round(mathRoundMultiplier * (toFloat(value) - incrementor)) / mathRoundMultiplier); }, [incrementor, mathRoundMultiplier, setValue, value]);
  const onIncrementUp = useCallback(() => {
    if (maxValue && value === maxValue) return;

    setValue(Math.round(mathRoundMultiplier * (toFloat(value) + incrementor)) / mathRoundMultiplier);
  }, [maxValue, incrementor, mathRoundMultiplier, setValue, value]);

  // Character length warning
  let helperText = null;
  if (characterLimit) {
    helperText = `${(value || '').length}/${characterLimit} characters`;
  }

  // Format validation error
  const errorTitle = props.title || props.placeholder;
  let errorMessage = error;
  if (errorMessage && errorTitle) {
    if (typeof errorTitle === 'string') {
      errorMessage = `${errorTitle} ${errorMessage.toLowerCase()}`;
    } else {
      errorMessage = (
        <React.Fragment>
          {errorTitle} {errorMessage.toLowerCase()}
        </React.Fragment>
      );
    }
  }
  let noticeMessage;
  if (!errorMessage && maxValueNotice && maxValue === value) {
    noticeMessage = maxValueNotice;
  }

  return (
    <FormGroup error={errorMessage} notice={noticeMessage} {...FormGroupProps}>
      <NumberField
        disabled={disabled}
        error={!!errorMessage}
        field={field}
        helperText={helperText}
        onChange={onChange}
        onPaste={onChange}
        onCut={onChange}
        onIncrement={!disabled && onIncrementUp}
        onDecrement={!disabled && (preventNegativeValues ? value > 0 && onIncrementDown : onIncrementDown)}
        size={size || 'medium'}
        type="number"
        value={value === null || value === undefined ? '' : String(value)}
        data-tour-id="number-input-field"
        {...inputProps}
      />
    </FormGroup>
  );
};

NumberInputField.defaultProps = {
  characterLimit: null,
  disabled: false,
  FormGroupProps: null,
  inline: undefined,
  isRequiredTitleLabel: false,
  maxValue: undefined,
  maxValueNotice: null,
  multiline: false,
  onTextChange: null,
  placeholder: null,
  preventNegativeValues: false,
  showSteppers: false,
  step: null,
  style: null,
  title: null,
  titleTooltip: null,
  type: 'text',
  validate: null,
  variant: 'outlined',
};

NumberInputField.propTypes = {
  characterLimit: PropTypes.number,
  disabled: PropTypes.bool,
  field: PropTypes.string.isRequired,
  FormGroupProps: PropTypes.object,
  inline: PropTypes.bool,
  isRequiredTitleLabel: PropTypes.bool,
  maxValue: PropTypes.number,
  maxValueNotice: PropTypes.string,
  multiline: PropTypes.bool,
  onTextChange: PropTypes.func,
  placeholder: PropTypes.string,
  preventNegativeValues: PropTypes.bool,
  showSteppers: PropTypes.bool,
  step: PropTypes.string,
  style: PropTypes.object,
  title: PropTypes.node,
  titleTooltip: PropTypes.string,
  type: PropTypes.string,
  validate: PropTypes.func,
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
};

export default NumberInputField;
