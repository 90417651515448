import React, { useState } from 'react';

import { useQuery } from '~/lazy_apollo/client';

import menuItemModifierQuery from '../../../../libs/gql/queries/menus/menuItemModifierQuery.gql';

import { useLoadedModifierGroups } from '../LoadedModifierGroupsProvider';
import SelectedNestedModifiers from '../SelectedNestedModifiers';

const getSelectedModifiers = modifierGroup => (
  modifierGroup.modifiers.map(modifier => (
    {
      menuItemModifierId: modifier.id,
      quantity: modifier.isDefault ? 1 : 0,
    }),
  )
);

// TODO: migrate the calculateOutOfStock and getExtraTitle functions to be exported helpers and not passed down props
const SelectedModifierQuery = ({ calculateOutOfStock, getExtraTitle, menuItemModifierId, nestingLevel }) => {
  const [loadedModifierGroups, setLoadedModifierGroups] = useState();
  const { updateLoadedModifierGroups } = useLoadedModifierGroups();
  const { data, loading } = useQuery(
    menuItemModifierQuery,
    {
      variables: {
        id: menuItemModifierId,
      },
    },
  );

  if (loading || !data || !data.menuItemModifier) {
    return null;
  }
  const { menuItemModifier } = data;
  const selectedModifierGroups = menuItemModifier.modifierGroups.map(modifierGroup => ({
    menuItemModifierGroupId: modifierGroup.id,
    selectedModifiers: getSelectedModifiers(modifierGroup),
  }));

  // Update state so infinite loop does not occur and write modifier groups to context
  if (!loadedModifierGroups) {
    setLoadedModifierGroups([...menuItemModifier.modifierGroups]);
    updateLoadedModifierGroups(menuItemModifier.modifierGroups);
  }

  return (
    <SelectedNestedModifiers
      calculateOutOfStock={calculateOutOfStock}
      defaultSelectedModifierGroups={selectedModifierGroups}
      getExtraTitle={getExtraTitle}
      modifierGroups={menuItemModifier.modifierGroups}
      nestingLevel={nestingLevel}
    />
  );
};

export default SelectedModifierQuery;
